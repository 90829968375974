<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col md="12">
        <b-card style="overflow: auto">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Judul" label-for="Judul">
                  <b-form-input
                    id="Judul"
                    type="text"
                    v-model="form.judul"
                    placeholder="Masukkan Judul"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Pilih Kategori Paket" label-for="paket">
                  <v-select
                    v-model="form.paket_id"
                    label="text"
                    :clearable="true"
                    :options="optpaket_id"
                    placeholder="Pilih Kategori Paket"
                  />
                  <!-- :reduce="(option) => option.value" -->
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Pilih Paket" label-for="paket">
                  <v-select
                    v-model="form.paket2_id"
                    label="text"
                    :clearable="true"
                    :options="optpaket2_id"
                    placeholder="Pilih Paket"
                  />
                  <!-- :reduce="(option) => option.value" -->
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Tanggal & Waktu Mulai"
                  label-for="start_time"
                >
                  <flat-pickr
                    v-model="form.start_time"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                    }"
                    placeholder="Tentukan Jadwal Mulai"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Tanggal & Waktu Akhir"
                  label-for="end_time"
                >
                  <flat-pickr
                    v-model="form.end_time"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                    }"
                    placeholder="Tentukan Jadwal Akhir"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Atur Tanggal di masing-masing ujian"
                  label-for="atur"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="form.is_date_in_subject"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div align="right">
                  <b-modal
                    v-model="ModalTambahMapel"
                    id="modal-tambah-sub"
                    centered
                    size="xl"
                    :title="titleModal"
                  >
                    <b-card-text>
                      <b-form>
                        <b-row>
                          <b-col
                            sm="4"
                            v-if="
                              form.paket2_id != null &&
                              form.paket2_id.is_contain_subcat == 1
                            "
                          >
                            <b-form-group
                              label="Sub Kategori"
                              label-for="Subtest"
                            >
                              <b-form-select
                                id="Subtest"
                                v-model="formSub.subcat_id"
                                :options="paketSubtestDataParent"
                                placeholder="Sub Kategori"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col sm="8">
                            <b-form-group
                              label="Nama Ujian"
                              label-for="nama-paket"
                            >
                              <b-form-input
                                v-model="formSub.name"
                                placeholder="Nama Ujian"
                                name="nama-paket"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group
                              label="Nama Ujian (singkat)"
                              label-for="harga"
                            >
                              <b-form-input
                                v-model="formSub.shortname"
                                name="shortname"
                                placeholder="shortname"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group label="Acak Soal" label-for="acak">
                              <b-form-select
                                v-model="formSub.shuffle_question"
                                :options="optquestion"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group
                              label="Tampilkan Hasil"
                              label-for="show_result"
                            >
                              <b-form-select
                                v-model="formSub.show_result"
                                :options="optresult"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col sm="12">
                            <b-form-group
                              label="Instruksi"
                              label-for="instruksi"
                            >
                              <quill-editor
                                id="Konten"
                                v-model="formSub.instruction"
                                :options="editor_options"
                              >
                              </quill-editor>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-card-text>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left mb-0"></p>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="outline-success"
                          class="mr-1 btn-icon"
                          @click.prevent="TambahSub"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />{{
                            label || "Tambah"
                          }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="float-right btn-icon"
                          @click.prevent="tutupModal"
                        >
                          <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                  <!-- <feather-icon icon="PlusIcon" /> -->
                </div>
              </b-col>
              <b-col md="12">
                <b-table
                  small
                  responsive="sm"
                  v-model="ujians"
                  :fields="
                    form.is_date_in_subject == false ||
                    form.is_date_in_subject == 0
                      ? fields
                      : fields2
                  "
                  :items="ujiandata"
                >
                  <template #head(index)="data">
                    <b-form-checkbox
                      @change="selectAll"
                      v-model="allSelected"
                    />
                  </template>
                  <template #cell(index)="data">
                    <b-form-checkbox
                      :value="data.item"
                      v-model="select_ujian"
                    />
                  </template>

                  <!-- A custom formatted column -->
                  <template #cell(action)="row">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="ModalHapus(row.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template #cell(start_time)="data">
                    <flat-pickr
                      v-model="data.item.start_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                  </template>
                  <template #cell(end_time)="data">
                    <flat-pickr
                      v-model="data.item.end_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                  </template>

                  <template #cell(timer)="data">
                    <!-- {{ data.item.timer }} -->
                    <b-form-input
                      v-model="data.item.timer"
                      size="sm"
                      placeholder="timer"
                      type="number"
                    />
                  </template>
                  <template #cell(passing_grade)="data">
                    <!-- {{ data.item.passing_grade }} -->
                    <b-form-input
                      v-model="data.item.passing_grade"
                      size="sm"
                      placeholder="passing_grade"
                      type="number"
                    />
                  </template>
                  <template #cell(sum_soal)="data">
                    <!-- {{ data.item.passing_grade }} -->
                    <!-- readonly -->
                    <b-form-input
                      :value="data.item.sum_soal"
                      size="sm"
                      placeholder="0"
                      type="number"
                      disabled
                    />
                  </template>
                </b-table>

                <section class="my-1" v-if="!form.paket2_id">
                  <b-alert show variant="danger" class="p-1">
                    <p class="text-center">
                      Silahkan pilih paket terlebih dahulu untuk memunculkan
                      daftar ujian.
                    </p>
                  </b-alert>
                </section>
              </b-col>
              <!-- {{ select_ujian }} -->
              <b-col md="6">
                <b-form-checkbox
                  checked="false"
                  name="check-button"
                  switch
                  inline
                  v-model="form.active"
                >
                  Aktifkan Jadwal (agar bisa diakses siswa) </b-form-checkbox
                ><br />
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  v-model="form.file_pembahasan"
                  switch
                  inline
                >
                  Pembahasan & Kunci Jawaban
                </b-form-checkbox>
              </b-col>
              <b-col md="12" class="mt-1">
                <b-form-group
                  label="Jadwal diperuntukkan untuk?"
                  label-for="jadwal"
                >
                  <!-- <b-form-group > -->
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="form.type"
                    name="radio-sub-component"
                  >
                    <b-form-radio value="member">Member</b-form-radio>
                    <b-form-radio value="non-member">Non Member</b-form-radio>
                  </b-form-radio-group>
                  <!-- </b-form-group> -->
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  class="mt-1"
                  label="Maksimal Kuota"
                  label-for="Maksimal Kuota"
                >
                  <b-form-input id="max_quota" v-model="form.max_quota" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="Maksimal Kuota">
                  <label class="font-weight-bold mt-1"
                    >Upload file format: jpg, png, pdf, doc, mp3, mp4
                    (opsional)</label
                  >
                  <b-form-file
                    id="Thumbnail"
                    v-model="form.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    no-drop
                    accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <!-- v-if="allowCreate()" -->
          <b-button
            variant="success"
            @click.prevent="Tambah"
            class="w-full mt-3"
          >
            Simpan
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="clearForm"
            class="w-full mt-3 mx-1"
          >
            Reset
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BFormRadioGroup,
  BFormRadio,
  BMedia,
  BOverlay,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  mixins: [heightTransition],
  components: {
    BFormRadio,
    vSelect,
    quillEditor,
    BMedia,
    BOverlay,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    "form.paket_id"(category) {
      if (category) {
        this.getPaket();
      }
    },
    "form.paket2_id"(paket) {
      if (paket) {
        this.getUjianPaket();
        this.isi = paket.value;
      } else {
        this.ujiandata = [];
      }
    },
  },
  data() {
    return {
      loading: false,
      ujiandata: [],
      ujians: [],
      mapels: [],
      optpaket_id: [],
      optpaket2_id: [],
      optujian: [],
      select_ujian: [],
      allSelected: false,
      ModalTambahMapel: false,
      // default data
      form: {
        judul: null,
        paket_id: null,
        paket2_id: null,
        start_time: null,
        end_time: null,
        active: false,
        type: "non-member",
        file_pembahasan: false,
        timer: 0,
        max_quota: 0,
        is_date_in_subject: false,
      },
      formSub: {
        id: null,
        ujian_id: null,
        subcat_id: null,
      },
      paketSubtestDataParent: [],
      titleModal: null,
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      isi: null,
      // ujians: [
      //   {
      //     ujian_id: null,
      //     end_time: new Date(),
      //     start_time: new Date(),
      //     timer_minute: 0,
      //     passing_grade: null,
      //   },
      // ],
      fields: [
        { key: "index", label: "" },
        { key: "name", label: "Ujian", sortable: true },
        { key: "sum_soal", label: "Soal", sortable: true },
        { key: "timer", label: "Timer (menit)", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        // { key: "action", label: "#" },
      ],
      fields2: [
        { key: "index", label: "" },
        { key: "name", label: "Ujian", sortable: true },
        { key: "start_time", label: "Tgl Mulai", sortable: true },
        { key: "end_time", label: "Tgl Berakhir", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "sum_soal", label: "Soal", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        // { key: "action", label: "#" },
      ],
    };
  },
  computed: {
    savedForm() {
      return this.$store.state.jadwaladmin.currentJadwal;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    TambahSub() {
      let payloadUjian = {
        user_id: this.user.id,
        category_paket_id: this.form.paket_id.id,
        name: this.formSub.name,
        shortname: this.formSub.shortname,
        show_result: this.formSub.show_result,
        shuffle_question: this.formSub.shuffle_question,
        instruction: this.formSub.instruction,
      };

      if (this.id) {
        payloadUjian.id = this.id;
      }

      this.loading = true;
      this.$store
        .dispatch("adminujian/save", [payloadUjian])
        .then((res) => {
          let respon = res.data;
          this.loading = false;
          this.ModalTambahMapel = false;
          // this.ModalEdit = false;
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
          if (this.id == null) {
            let payload = {
              paket_id: this.form.paket2_id.id,
              ujian_id: respon.id,
            };

            if (this.form.paket2_id.is_contain_subcat == 1) {
              payload.subcat_id = this.formSub.subcat_id;
            }

            if (this.id) {
              payload.id = this.id;
            }

            this.$store
              .dispatch("adminujian/storeSubtestPaket", [payload])
              .then(() => {
                this.id = null;
                this.displaySuccess({
                  text:
                    "Ujian berhasil di" +
                    this.activeAction +
                    " di Sub Kategori",
                });
                this.id = null;
                this.ModalSub = false;
                this.formSub = {
                  subcat_id: null,
                  name: null,
                  shortname: null,
                  show_result: null,
                  shuffle_question: null,
                  instruction: null,
                };
                const isPaketContainSubcat = this.paket?.is_contain_subcat == 1;
                if (isPaketContainSubcat) {
                  // window.location.href = `/master-admin/paket-tryout/detail/${this.$route.params?.id}?active_tab=subtest`;
                } else {
                  // this.getDataPaketSubtest();
                }
              })
              .catch((e) => {
                this.displayError(e);
                return false;
              });

            this.getUjianPaket();
          }
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getDataPaketSubtestParent() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: this.form.paket2_id.id,
        ujian_id: 0,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          let paketSubtestDataParent = response.data.data;
          paketSubtestDataParent.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.paketSubtestDataParent = paketSubtestDataParent;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    createMapel() {
      const dataForm = Object.assign({}, this.form);
      this.$store.commit("jadwaladmin/SET_CURRENT_JADWAL", dataForm);
      if (this.form.paket2_id && this.form.paket2_id.is_contain_subcat == 1) {
        this.getDataPaketSubtestParent();
        this.ModalTambahMapel = true;
        return true;
      }
      // this.$router.push(
      //   `/master-admin/paket-tryout/detail/${this.isi}?active_tab=subtest`
      // );
      this.ModalTambahMapel = true;
    },
    selectAll(checked) {
      this.select_ujian = checked ? this.ujiandata.slice() : [];
    },
    clearForm() {
      this.dataSoal = [
        {
          judul: null,
          name: null,
          file: null,
          option: null,
          dataOptions: [],
          secretKey: null,
          bobot: 1,
        },
      ];
      this.type = null;
      this.paket_id = null;

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    addAttachSoal(ind, e) {
      document.getElementById(`file_soal_${ind}`).click();
    },
    addAttachSoalOption(ind, e) {
      document.getElementById(ind).click();
    },
    addSoal() {
      if (this.dataSoal.length >= limitSoal) {
        this.$vs.notify({
          title: "Batas Pembuatan Soal Tercapai",
          text: "",
          variant: "warning",
          position: "top-right",
        });
        return false;
      }

      let data = {
        no: this.dataSoal.length + 1,
        judul: null,
        name: null,
        file: null,
        option: null,
        dataOptions: [],
        // is_correct: null,
        bobot: 1,
      };

      if (this.selectedOption != null) {
        for (let i = 0; i < this.selectedOption; i++) {
          let option = this.getOption(i);
          data.dataOptions.push({
            key: i,
            option,
            file: null,
            content: null,
          });
        }
      }

      //
      this.dataSoal.push(data);
    },
    updateDataSoal(val) {
      let vm = this;
      this.$route.meta.breadcrumb[2].url =
        "/soal/daftarsoal?mp=" + this.$route.query.mp;
      val.forEach((item, index) => {
        // Change when user choose Jumlah Opsi
        if (form.selectedOption != null) {
          let numOpsi = parseInt(form.selectedOption.judul);
          if (form.dataOptions.length > 0) {
            form.dataOptions = [];
          }

          if (form.dataOptions.length < 1) {
            for (let i = 0; i < numOpsi; i++) {
              let option = this.getOption(i);
              vm.dataSoal[index].dataOptions[i] = {
                key: i,
                option,
                file: null,
                content: null,
              };
            }
          }
        }
      });
    },
    getEditorOptions(id_file_input) {
      // get event link click in dynamic options
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
            handlers: {
              link: function (val) {
                document.getElementById(id_file_input).click();
              },
            },
          },
        },
      };
    },
    getOption(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },

    getKunjaw(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },
    removeOptions(ind) {
      if (this.dataOptions.length > 1) {
        this.dataOptions.splice(ind, 1);
      }
    },
    optionNull() {
      let hasOption = this.dataSoal.filter((soal) =>
          soal.dataOptions.find(
            (item) =>
              form.content == null || form.content == "" || form.option == null
          )
        ),
        hasNull = 0;

      this.dataSoal.forEach((soal) => {
        let find = soal.dataOptions.find(
          (item) =>
            form.content == null || form.content == "" || form.option == null
        );

        if (find) {
          hasNull++;
        }
      });

      return this.type == "pg" ? hasNull > 0 : false;
    },
    addOption() {
      // untuk menambah opsi
      this.dataOptions.push({
        option: null,
        content: null,
      });
    },
    fileToBase64(fileObj) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileObj);
      });
    },
    async Tambah() {
      if (this.form.paket_id == null || this.form.paket_id == "") {
        this.pesanGagal();
        return false;
      }

      if (this.select_ujian == null || this.select_ujian == "") {
        this.displayWarning({
          title: "Notifikasi",
          text: "Harap Pilih minimal 1 Ujian yang akan dimasukkan ke jadwal",
        });
        return false;
      }

      var payload = {
        paket_id: this.form.paket2_id.id,
        name: this.form.judul,
        max_quota: this.form.max_quota,
        type: this.form.type,
        is_date_in_subject: this.form.is_date_in_subject == true ? 1 : 0,
        active: this.form.active == true ? 1 : 0,
        file_pembahasan: this.form.file_pembahasan == true ? 1 : 0,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
      };

      if (this.id) {
        payload.id = this.id;
      }
      this.loading = true;
      this.$store
        .dispatch("jadwaladmin/save", payload)
        .then(async (jadwal) => {
          this.$store.commit("jadwaladmin/SET_CURRENT_JADWAL", null);
          this.displaySuccess({
            text: "Jadwal berhasil ditambah",
          });
          const jadwalId = jadwal.id;
          const payloadUjian = [];
          this.select_ujian.forEach((ujian) => {
            let jadwalData = {
              jadwal_id: jadwal.id,
              ujian_id: ujian.ujian_id,
              timer: ujian.timer == null ? 0 : ujian.timer,
              start_time: jadwal.start_time,
              end_time: jadwal.end_time,
              sum_soal: ujian.sum_soal,
              passing_grade:
                ujian.passing_grade == null ? 0 : ujian.passing_grade,
            };

            if (jadwal.is_date_in_subject == 1) {
              jadwalData.start_time = ujian.start_time;
              jadwalData.end_time = ujian.end_time;
            }

            payloadUjian.push(jadwalData);
          });

          await this.$store.dispatch("jadwaladmin/saveujian", payloadUjian);
          this.loading = false;
          this.displaySuccess({
            text: "Jadwal Ujian berhasil ditambah",
          });

          this.getUjianPaket();
          this.$router.push(`/master-admin/jadwal-admin/detail/${jadwal.id}`);
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.dataSoal[id.soal].dataOptions[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[id.soal].dataOptions[id.opsi].file =
          URL.createObjectURL(objFile);
      } else {
        this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.dataSoal[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.dataSoal[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.dataSoal[ind].file = null;
      this.dataSoal[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      this.dataSoal[id.soal].dataOptions[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    async getMyMapel() {
      const params = {
        guru_id: this.profile.id,
      };
      const mapels = await this.$store.dispatch("mapel/getMyMapel", params);
      mapels.map((item) => {
        if (form.mapel) {
          this.mapels.push(form.mapel);
        }
      });
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredUjians) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredUjians.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = form.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getPaketTryout() {
      const payload = {};
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
          if (this.savedForm && this.savedForm?.paket2_id) {
            this.form.paket2_id = this.savedForm?.paket2_id;
          }
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    getPaket() {
      this.form.paket2_id = null;
      const payload = {
        category_id: this.form.paket_id != null ? this.form.paket_id.id : 0,
      };
      this.loading = true;
      this.$store
        .dispatch("paketTryout/index", payload)
        .then((response) => {
          this.loading = false;
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket2_id = paket_id;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getUjianPaket() {
      this.loading = true;
      const payload = {
        paket_id: this.form.paket2_id?.id,
      };

      if (payload.paket_id) {
        const { is_contain_subcat } = this.form.paket2_id;
        let isPaketContainSubcat = is_contain_subcat == 1 ? true : false;

        this.$store
          .dispatch("ujian/subtesPaket", payload)
          .then((response) => {
            this.loading = false;
            let results = response.data?.data;
            let ujians = [];

            if (isPaketContainSubcat) {
              results.forEach((subcat) => {
                let subtests = subcat.items
                  .filter((subtest) => subtest.ujian)
                  .map((subtest) => subtest.ujian);
                ujians.push(...subtests);
              });
            } else {
              let subtests = results
                .filter((subtest) => subtest.ujian)
                .map((subtest) => subtest.ujian);
              ujians.push(...subtests);
            }

            const payloadUjian = ujians.map((ujian) => ({
              jadwal_id: null,
              ujian_id: ujian.id,
              name: ujian.name,
              sum_soal: ujian.sum_soal,
              timer: 0,
              passing_grade: 0,
              start_time: null,
              end_time: null,
            }));
            this.ujiandata = payloadUjian;

            // this.totalRows = this.ujiandata.length;
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("errorData", error);
          });
      }
    },
  },
  mounted() {
    this.getPaketTryout();
    if (this.savedForm) {
      this.form.judul = this.savedForm?.judul;
      this.form.active = this.savedForm?.active;
      this.form.start_time = this.savedForm?.start_time;
      this.form.end_time = this.savedForm?.end_time;
      this.form.file_pembahasan = this.savedForm?.file_pembahasan;
      this.form.is_date_in_subject = this.savedForm?.is_date_in_subject;
      this.form.max_quota = this.savedForm?.max_quota;
      this.form.paket_id = this.savedForm?.paket_id;
      this.form.timer = this.savedForm?.timer;
      this.form.type = this.savedForm?.type;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
